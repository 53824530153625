import NavBar from '@/components/global-components/navbar/Navbar';

import theme from '@/themes/index';

const Header = ({
  content,
  isDesktop, 
}) => {
  return (
    <>
      <header>
        <NavBar content={content} isDesktop={isDesktop} />
      </header>
      <style jsx>{`
        div {
          display: flex;
          height: 6rem;
          justify-content: space-between;
        }

        header {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }

        div.logo {
          justify-content: flex-start;
        }

        div.logo label {
          width: 8rem;
          font-family: ${theme.fontFamilies.montserratBold};
          font-weight: bolder;
          font-size: 0.7rem;
          margin: 2.3rem 0;
        }

        img {
          padding: 1rem 0 1rem 2rem;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div {
            justify-content: center;
          }

          div.logo label {
            font-size: 2rem;
            margin: 1rem 0;
          }

          header {
            height: 7.5rem;
          }
        }
      `}</style>
      <style global jsx>{`
        div.menu .svg-inline--fa.fa-w-14 {
          width: 3rem;
          height: 3rem;
          margin: 1.5rem 1.125rem 1.5rem;
        }
      `}</style>
    </>
  );
};

export default Header;
