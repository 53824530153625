import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
// import { createBrowserHistory } from 'history';
// const browserHistory = createBrowserHistory({
//   basename: '', 
// });
var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true,
};
var appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=3a91f258-1a70-4a05-9541-a8dd5aeb5aa0;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
    enableAutoRouteTracking: true,

    // *** Add the Click Analytics plug-in. ***
    extensions: [clickPluginInstance],
    extensionConfig: {
      // *** Add the Click Analytics plug-in. ***
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});
appInsights.loadAppInsights();

import { library } from '@fortawesome/fontawesome-svg-core';
import { MsalProvider } from '@azure/msal-react';
// MSAL imports
import {
  PublicClientApplication, EventType 
} from '@azure/msal-browser';
import { msalConfig } from './apiConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise().then(handleResponse).catch(error => {
  console.log(error);
});

function handleResponse(resp) {
  //debugger;
}

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

import {
  faAngleRight,
  faAngleLeft,
  faTimes,
  faShare,
  faMapMarker,
  faCalendar,
  faSun,
  faEnvelope,
  faPhone,
  faMap,
  faChevronDown,
  faChevronUp,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagramSquare,
  faFacebookSquare,
  faYoutubeSquare,
  faLinkedin,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faGooglePlus,
  faPinterest,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

// Components
import MediaQuery from '@/components/global-components/media-query/MediaQuery';
import MetaData from '@/components/global-components/meta/MetaData';
import Header from '@/components/global-components/Header';
import Footer from '@/components/global-components/footer/Footer';
import ScrollButton from '@/components/global-components/ScrollButton';

// Themes
import theme from '@/themes/index';

library.add(
  faAngleRight,
  faAngleLeft,
  faInstagramSquare,
  faFacebookSquare,
  faYoutubeSquare,
  faLinkedin,
  faTimes,
  faMapMarker,
  faCalendar,
  faSun,
  faShare,
  faEnvelope,
  faPhone,
  faMap,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faChevronDown,
  faChevronUp,
  faGooglePlus,
  faPinterest,
  faTwitter,
  faArrowUp
);

const App = ({
  children,
  content,
  meta,
  viewPorts,
  setDesktop,
  isDesktop,
  setMobile,
  isMobile,
  setTablet,
}) => {
  return (
    <>
      <MsalProvider instance={msalInstance}>
        <MetaData {...meta} />
        <main>
          <Header content={content} isDesktop={isDesktop} />
          {children}
        </main>
        <footer>
          <Footer content={content.footer} isDesktop={isDesktop} />
        </footer>
        <MediaQuery
          viewPorts={viewPorts}
          setDesktop={setDesktop}
          setMobile={setMobile}
          setTablet={setTablet}
        />
        <ScrollButton />
      </MsalProvider>
      <style jsx>{`
        footer {
          height: 100%;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          footer {
            height: 25rem;
          }
        }
      `}</style>
    </>
  );
};

export default App;
