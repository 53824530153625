export default {
  buttons: {
    colors: {
      primary: '#00E065',
      primaryHover: '#19FF83',
      primaryPress: '#6EECB4',
      primaryDisable: '#E5E5E5',
      secondary: '#292929',
      secondaryHover: '#BBBBBB',
      secondaryPress: '#E5E5E5',
      secondaryDisable: '#EEEEEE',
      ghost: 'transparent',
      ghostHover: '#19FF83',
      ghostPress: '#6EECB4',
      ghostDisable: 'transparent',
    },
    fontColors: {
      primary: '#FFFFFF',
      secondary: '#000000',
    },
  },
  backgroundColors: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    neutral: '#E5E5E5',
    optional1: '#ededed',
    optional2: '#292929',
  },
  fontColors: {
    primary: '#292929',
    secondary: '#363940',
    neutral: '#FFFFFF',
    optional1: '#95A1BB',
    optional2: '#901BE1',
    optional3: '#BBBBBB',
    optional4: '#6E38F7',
    optional5: '#00E065',
  },
  fontFamilies: {
    openSans: 'OpenSansRegular',
    openSansBold: 'OpenSansBold',
    OpenSansSemiBold: 'OpenSansSemiBold',
    montserrat: 'MontserratRegular',
    montserratBold: 'MontserratBold',
    montserratSemiBold: 'MontserratSemiBold',
    montserratMedium: 'MontserratMedium',
    montserratLight: 'MontserratLight',
    rubikMedium: 'RubikRegular',
  },
  brandColors: {
    primary: '#1D1D1B',
  },
  breakpoints: {
    mobileMax: '595px',
    tabletMin: '596px',
    tabletMax: '834px',
    desktopMin: '835px',
    desktopMax: '1366px',
  },
};
