//Node Modules
import PropTypes from 'prop-types';

//Themes
import theme from '@/themes/index';

const propTypes = {
  alt: PropTypes.string.isRequired,
  imgPath: PropTypes.string.isRequired,
};

const NavImg = ({
  alt,
  imgPath, 
}) => (
  <>
    <li className={'img-navbar-item'}>
      <a href='/'>
        <img src={imgPath} alt={alt} />
      </a>
    </li>
    <style jsx>{`
      .img-navbar-item {
        position: relative;
        cursor: pointer;
        z-index: 200;
      }

      .img-navbar-item img {
        padding: 0;
        min-width: unset;
        min-height: unset;
        max-width: unset;
        max-height: unset;
        object-fit: contain;
        width: 3.4rem;
        display: block;
        margin-right: 5px;
      }

      a {
        width: 7em;
        height: 100%;
        text-decoration: none;
        color: ${theme.fontColors.secondary};
        align-items: center;
        display: flex;
      }

      a span {
        font-family: ${theme.fontFamilies.montserratBold};
        font-weight: bolder;
        font-size: 9px;
        line-height: 1.2em;
        cursor: pointer;
        display: block;
        width: 6.4em;
      }
      @media only screen and (min-width: 1050px) {
        .img-navbar-item img {
          width: 14.875rem;
          height: 5.439375rem;
        }

        .img-navbar-item {
          width: 100px;
          height: auto;
          top: 0;
        }
      }

      @media only screen and (min-width: 860px) {
        .img-navbar-item {
          width: 140px;
          height: 64.9px;
        }
      }

      @media only screen and (min-width: 910px) {
        .img-navbar-item {
          width: 167px;
          height: 76px;
        }
      }
    `}</style>
  </>
);

NavImg.propTypes = propTypes;

export default NavImg;
