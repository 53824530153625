import {
  useState, memo, useCallback, useEffect
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Themes
import theme from '@/themes/index';

const ScrollButton = () => {
  const pixelsUntilShown = 700;

  const [yAxis, setYAxis] = useState(0);

  const setScroll = useCallback(() => {
    setYAxis(window.pageYOffset);
  })

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener('scroll', setScroll);
    }

    watchScroll();
    return () => {
      window.removeEventListener('scroll', setScroll);
    }
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {yAxis > pixelsUntilShown ? (<button onClick={scrollToTop} className='scroll-button'>
        <FontAwesomeIcon icon='arrow-up' title='Scroll to top' />
      </button>) : null}
      <style>{`
        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          .scroll-button {
            display: block;
            position: fixed;
            background-color: #c7c7c7;
            border: none;
            width: 3.6rem;
            height: 3.6rem;
            border-radius: 50%;
            border: 1px solid #c7c7c7;
            right: 2.7rem;
            top: 40%;
            cursor: pointer;
            color: white;
          }
        }
      `}</style>
    </>
  );
}

export default memo(ScrollButton);