import Head from 'next/head';
import { useRouter } from 'next/router';

const MetaData = ({
  canonicalUrl,
  headTitle,
  metaDescription,
  noIndex, 
}) => {
  const defaultMetaDescription = '';
  const description = metaDescription || defaultMetaDescription;

  // Dynamically generate the proper Canonical URL.
  const router = useRouter();
  const cleanPath = router.asPath.split('#')[0].split('?')[0];
  const finalCanonicalUrl = `${canonicalUrl}` + (router.asPath === '/' ? '' : cleanPath);

  return (
    <Head>
      <title>{headTitle}</title>
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0'
      />

      <meta name='description' content={description} />
      <meta name='title' content={headTitle} />
      {noIndex && <meta name='robots' content='noindex,nofollow' />}
      <link rel='canonical' href={finalCanonicalUrl} />
      <link rel='shortcut icon' href='/favicon.png' />
      {/* <link
        rel='preload'
        href='/assets/fonts/rubik-regular.eot'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/assets/fonts/montserrat-regular.eot'
        as='font'
        crossOrigin=''
      />
      <link
        rel='preload'
        href='/assets/fonts/open-sans-regular.eot'
        as='font'
        crossOrigin=''
      /> */}
    </Head>
  );
};

export default MetaData;
