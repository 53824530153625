// Node Modules
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nanoid } from 'nanoid';

// Themes
import theme from '@/themes/index';

const SocialNetwork = ({
  icons = [],
  backgroundColor,
}) => {
  return (
    <>
      <div className='icons-container'>
        {icons.map(({
          socialNetwork: network, 
        }) => (
          <a key={nanoid()} href={network.url} target='_blank' rel="noreferrer">
            <FontAwesomeIcon
              color={backgroundColor ?? ''}
              icon={['fab', network.icon]}
            />
          </a>
        ))}
      </div>
      <style jsx>{`
        div.icons-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          max-width: 16rem;
        }

        div.icons-container a {
          text-decoration: none;
          color: inherit;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.icons-container {
            padding: 0 1rem;
            max-width: 8.75rem;
          }

          div.icons-container a {
            padding-top: 0;
          }
        }
      `}</style>
      <style global jsx>{`
        svg {
          width: 1rem;
        }
      `}</style>
    </>
  );
};

export default SocialNetwork;
