import { LogLevel } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
let ua = ''
if (typeof window !== 'undefined') {
  ua = window.navigator.userAgent;
}

const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to MSAL (Microsoft Authentication Library) on creation
export const msalConfig = {
  auth: {
    clientId: 'cf909a29-d35d-469e-9638-ec049057fd82',
    authority: 'https://cpicostaricastudents.b2clogin.com/cpicostaricastudents.onmicrosoft.com/b2c_1_signup_signin/',
    knownAuthorities: ['cpicostaricastudents.b2clogin.com/cpicostaricastudents.onmicrosoft.com'], 
    redirectUri: 'https://cpi-cdn-endpoint.azureedge.net', // TODO: extract this to environment configuration.
    //redirectUri: 'http://localhost:3000', // Use this for local testing.
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequestConfig = {
  scopes: ['https://cpicostaricastudents.onmicrosoft.com/apiinscripcion/Estudiante.Matricular'],
};

// Add here the endpoints for the CPI API
export const apiEndpoints = {
  baseUrl: 'https://www.studyspanishcostarica.com/apiinscripcion',

  listaSedesDePrograma: ProgramId => `/api/Consulta/SedeLista/${ProgramId}`, // GET
  programaListaEndpoint: Language => `/api/Consulta/ProgramaLista/${Language}`, // GET
  programaCategoriaEndpoint: (Language, CategoryId) => `/api/Consulta/ProgramaCategoria/${Language}/${CategoryId}`, // GET
  zonaHorariaEndpoint: Language => `/api/Consulta/ZonaHoraria/${Language}`, // GET
  detalleProgramaEndpoint: (Language, ProgramId) => `/api/Consulta/ProgramaDetalle/${Language}/${ProgramId}`, // GET

  fechaCalendarioEndpoint: TimeZoneId => `/api/Consulta/FechaCalendario/${TimeZoneId}`, // GET

  programaVirtualOpcionHorarioPrivadoEndpoint: (Language, ProgramId, StartDate, TimeZoneId) => `/api/Consulta/ProgramaOpcionHorarioVirtualPrivado/${Language}/${ProgramId}/${StartDate}/${TimeZoneId}`, // GET
  programaVirtualCrearHorarioPrivadoEndpoint: () => '/api/Consulta/ProgramaCrearHorarioVirtualPrivado', // POST
  programaVirtualMatricularEndpoint: () => '/api/Matricula/MatricularProgramaVirtual', // POST
  programaVirtualRecuperarMatriculaEndpoint: Language => `/api/Matricula/RecuperaMatriculaVirtual/${Language}`, // GET

  programaPresencialDetalleEndpoint: () => '/api/Consulta/ProgramaPresencialDetalle', // POST
  programaPresencialMatricularEndpoint: () => '/api/Matricula/MatricularProgramaPresencial', // POST

  pruebaEndpoint: () => '/api/matricula/prueba/',
};