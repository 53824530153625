import { useEffect } from 'react';

import Themes from '@/themes/index';

const MediaQuery = ({
  viewPorts,
  setDesktop,
  setMobile,
  setTablet, 
}) => {
  useEffect(() => {
    const updateSize = () => {
      const width = window.innerWidth;
      const {
        isDesktop,
        isMobile,
        isTablet, 
      } = viewPorts;
      let {
        desktopMin,
        mobileMax, 
      } = Themes.breakpoints;
      desktopMin = +desktopMin.replace('px', '');
      mobileMax = +mobileMax.replace('px', '');

      if (!isMobile && width <= mobileMax) {
        setMobile();
      } else if (!isTablet && width > mobileMax && width < desktopMin) {
        setTablet();
      } else if (!isDesktop && width >= desktopMin) {
        setDesktop();
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [viewPorts]);

  return <></>;
};

export default MediaQuery;
