//Node Modules
import PropTypes from 'prop-types';

//Themes
import theme from '@/themes/index';

const propTypes = {
  active: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

const MobileMenuTrigger = ({
  active,
  toggleNav, 
}) => (
  <>
    <li
      className={`navbar-mobile-menu-trigger hamburger-squeeze ${
        active ? 'is-active' : ''
      }`}
      onClick={toggleNav}
      tabIndex='0'
    >
      <span className='hamburger-box'>
        <span className='hamburger-inner'></span>
      </span>
    </li>
    <style jsx>
      {`
        .hamburger-box {
          width: 27px;
          height: 24px;
          display: inline-block;
          position: relative;
        }

        .hamburger-inner {
          display: block;
          top: 50%;
          margin-top: -2px;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
          width: 100%;
          height: 4px;
          background-color: ${theme.fontColors.secondary};
          border-radius: 4px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
        }

        .hamburger-inner::before,
        .hamburger-inner::after {
          content: '';
          display: block;
        }

        .hamburger-inner::before {
          top: -9px;
        }

        .hamburger-inner::after {
          bottom: -9px;
        }

        .hamburger-squeeze .hamburger-inner {
          transition-duration: 0.075s;
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        .hamburger-squeeze .hamburger-inner::before {
          transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }

        .hamburger-squeeze .hamburger-inner::after {
          transition: bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        .hamburger-squeeze.is-active .hamburger-inner {
          transform: rotate(45deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .hamburger-squeeze.is-active .hamburger-inner::before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }

        .hamburger-squeeze.is-active .hamburger-inner::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease,
            transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .navbar-mobile-menu-trigger {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 30px;
          padding: 0;
          background: transparent;
          border: none;
          position: relative;
          cursor: pointer;
        }

        .navbar-mobile-menu-trigger div.mobile-menu-line {
          height: 4px;
          border-radius: 3px;
          opacity: 1;
          background: ${theme.fontColors.secondary};
        }

        .navbar-mobile-menu-trigger.menu-active {
          justify-content: center;
        }

        .navbar-mobile-menu-trigger.menu-active div.mobile-menu-line.top {
          position: relative;
          top: 3px;
        }

        .navbar-mobile-menu-trigger.menu-active div.mobile-menu-line.middle {
          opacity: 0;
        }

        .navbar
          .navbar-mobile-menu-trigger.menu-active
          div.mobile-menu-line.bottom {
          position: relative;
          top: -5px;
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          ul.navbar .navbar-mobile-menu-trigger {
            width: 125px;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          .navbar-mobile-menu-trigger {
            display: none;
          }
        }
      `}
    </style>
  </>
);

MobileMenuTrigger.propTypes = propTypes;

export default MobileMenuTrigger;
