// Themes
import theme from '@/themes/index';

const Container = ({
  children,
  style, 
}) => {
  return (
    <>
      <div style={style}>{children}</div>
      <style jsx>{`
        div {
          width: 100%;
          max-width: 87.5rem;
          margin: 0 auto;
          padding: 0 0.625rem;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div {
            padding: 0;
          }
        }
      `}</style>
    </>
  );
};

export default Container;
