// Node Modules
import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';

// Themes
import theme from '@/themes/index';

// Components
import Container from '@/components/global-components/Container';
import SocialNetwork from '@/components/global-components/SocialNetwork';
import Item from './sub-components/Item';

const Footer = ({
  content: {
    items, 
  },
  isDesktop,
}) => {
  return (
    <>
      
      <div className='container'>
        <Container 
          style={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'space-around',
          }}
        >
          {items.map(({
            footerItems: item, 
          }) => {
            switch (item.type) {
              case 'Text':
                return (
                  <div key={nanoid()} className='section cpi'>
                    <a href={item.url} className='title'>
                      {item.title}
                    </a>
                    <RichText render={item.description} />
                  </div>
                );
              case 'Contact':
                return (
                  <div key={nanoid()} className='section'>
                    <a href={item.url} className='title'>
                      {item.title}
                    </a>
                    <SocialNetwork
                      icons={item.socialNetworks}
                      backgroundColor={theme.backgroundColors.primary}
                    />
                  </div>
                );
              default:
                return (
                  <div key={nanoid()} className='section'>
                    <a href={item.url} className='title'>
                      {item.title}
                    </a>
                    <Item links={item.navLinks} />
                  </div>
                );
            }
          })}
        </Container>
      </div>
      <style jsx>{`
        div.container {
          width: 100%;
          height: 100%;
          background: ${theme.backgroundColors.optional2};
          color: ${theme.fontColors.neutral};
          padding-top: 3.125rem;
          display: flex;
          flex-direction: column;
          text-align: left;
        }

        div.container .section {
          display: flex;
          flex-direction: column;
          padding: 0 0 2rem 1.5rem;
        }

        div.container .section.cpi {
          text-align: center;
        }

        div.container .section .title {
          font-family: ${theme.fontFamilies.OpenSansSemiBold};
          padding-bottom: 1rem;
          text-decoration: none;
          color: inherit;
          font-size: 1.125rem;
        }

        div.container .section .title:hover {
          text-decoration: underline;
        }

        div.container .section .cpi-description {
          font-family: ${theme.fontFamilies.OpenSansSemiBold};
          line-height: 1.5rem;
          margin: 0 1.5rem;
        }

        div.container .section .contact-title {
          padding-bottom: 0.875rem;
        }

        div.container .section p {
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.container {
            flex-direction: row;
            justify-content: space-between;
            padding-top: 6.3125rem;
            text-align: left;
          }

          div.container .section {
            max-width: 11.25rem;
          }

          div.container .section.cpi {
            text-align: left;
          }

          div.container .section .title {
            padding-bottom: 1.375rem;
          }

          div.container .section .cpi-description {
            margin: 0;
          }

          div.container .section p {
            color: ${theme.fontColors.neutral};
          }
        }
          div.container .section .cpi-description {
            margin: 0;
          }
      `}</style>
      <style global jsx>{`        
        div.container .section > p {
          margin-block-start: 0;
          font-family: ${theme.fontFamilies.OpenSansSemiBold};
          line-height: 1.5rem;
        }

        div.container .section .icons-container {
          justify-content: space-between;
          padding-left: 0;
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.container .section .icons-container {
            padding: 0;
          }
        }
      `}</style>
    </>
  );
};

export default Footer;
