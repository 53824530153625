// Node Modules
import { nanoid } from 'nanoid';

const Item = ({
  links, 
}) => (
  <>
    <div className='links-container'>
      {links?.map(({
        navLink: link,
      }) => (
        <a target={link.target} className='body-text-small' key={nanoid()} href={link.link}>
          {link.title}
        </a>
      ))}
    </div>
    <style jsx>{`
      div.links-container {
        display: flex;
        flex-direction: column;
      }

      div.links-container a {
        text-decoration: none;
        color: inherit;
        padding-bottom: 0.625rem;
        line-height: 1.5rem;
      }

      div.links-container a:hover {
        text-decoration: underline;
      }
    `}</style>
  </>
);

export default Item;
